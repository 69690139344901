
@mixin word-wrap() {
	word-break:     break-word;
	-webkit-hyphens: auto;
	-moz-hyphens:    auto;
	hyphens:         auto;
}

@mixin ellipsis() {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// Clearfix
@mixin clearfix() {
	&:after {
		content: "";
		display: table; 
		clear: both;
	}
}

// Reset list
@mixin unstyled-list() {
	margin:0;
	padding:0;
	list-style:none;
}


@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}


@mixin responsive-image() {
	width: 100%;
	max-width: 100%; 
	display:block; 
	height: auto;
}




// hover status - media interaction
@mixin hover-interaction($hover) {
  @include mq($hover-interaction: $hover) {
    @content;
  }
}




@mixin horizontal-gradient($from, $to, $stop: 100%) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);

  /* IE10+ */
  background: -ms-linear-gradient(left, $from 0%, $to $stop);

  /* Standard */
  background: linear-gradient(to right, $from 0%, $to $stop);

  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}





@mixin linear-gradient($angle, $gradient1, $gradient2) {
	background-color: $gradient2; /* Fallback Color */
	background-image: -webkit-gradient(linear, $angle, from($gradient1), to($gradient2)); /* Saf4+, Chrome */
	background-image: -webkit-linear-gradient($angle, $gradient1, $gradient2); /* Chrome 10+, Saf5.1+, iOS 5+ */
	background-image:    -moz-linear-gradient($angle, $gradient1, $gradient2); /* FF3.6 */
	background-image:     -ms-linear-gradient($angle, $gradient1, $gradient2); /* IE10 */
	background-image:      -o-linear-gradient($angle, $gradient1, $gradient2); /* Opera 11.10+ */
	background-image:         linear-gradient($angle, $gradient1, $gradient2);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=$angle,StartColorStr='#{$gradient1}', EndColorStr='#{$gradient2}');
}


@mixin blur($radius) {
	-webkit-filter: blur($radius); /* Chrome, Safari */
	-ms-filter: blur($radius); /* IE12? */
	filter: url("data:image/svg+xml;utf8,<svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"blur\"><feGaussianBlur stdDeviation=\"#{$radius}\" /></filter></svg>#blur"); /* Firefox, Safari, Chrome*/
	filter: progid:DXImageTransform.Microsoft.Blur(Strength=$radius); /* IE9 */
	filter: blur($radius);
}

@mixin addfontsize($font-size: 16) {
	font-size: $font-size + px;

	@include min-screen($md) {
		font-size: $font-size + 2px;
	}
}

@mixin addfontsizetitle($font-size: 20) {
	font-size: $font-size + px;

	@include min-screen($md) {
		font-size: $font-size + 2px;
	}
	@include min-screen($lg) {
		font-size: $font-size + 4px;
	}
}


//Animations
@mixin keyframes( $animationName )
{
	@-webkit-keyframes $animationName {
		@content;
	}
	@-moz-keyframes $animationName {
		@content;
	}
	@-o-keyframes $animationName {
		@content;
	}
	@keyframes $animationName {
		@content;
	}
}

@mixin animation ($delay, $duration, $animation) {
	-webkit-animation-delay: $delay;
	-webkit-animation-duration: $duration;
	-webkit-animation-name: $animation;
	-webkit-animation-iteration-count: $iterations;
	-webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

	-moz-animation-delay: $delay;
	-moz-animation-duration: $duration;
	-moz-animation-name: $animation;
	-moz-animation-iteration-count: $iterations;
	-moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

	-o-animation-delay: $delay;
	-o-animation-duration: $duration;
	-o-animation-name: $animation;
	-o-animation-iteration-count: $iterations;
	-o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

	animation-delay: $delay;
	animation-duration: $duration;
	animation-name: $animation;
	animation-iteration-count: $iterations;
	animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}





