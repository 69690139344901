/*
* OptimaLTStd font

@font-face {
	font-family: 'OptimaLTStd';
	font-weight: normal;
	font-style: normal;
	src: url('../../webfonts/3345536_B_0.eot');
	src: url('../../webfonts/3345536_B_0.eot?#iefix') format('embedded-opentype'),
			 url('../../webfonts/3345536_B_0.eot.woff2') format('woff2'),
			 url('../../webfonts/3345536_B_0..woff') format('woff'),
			 url('../../webfonts/3345536_B_0..ttf') format('truetype');
}
@font-face {
	font-family: 'OptimaLTStd';
	font-weight: normal;
	font-style: italic;
	src: url('../../webfonts/3345536_6_0.eot');
	src: url('../../webfonts/3345536_6_0.eot?#iefix') format('embedded-opentype'),
			 url('../../webfonts/3345536_6_0.woff2') format('woff2'),
			 url('../../webfonts/3345536_6_0.woff') format('woff'),
			 url('../../webfonts/3345536_6_0.ttf') format('truetype');
}
@font-face {
	font-family: 'OptimaLTStd';
	font-weight: bold;
	font-style: normal;
	src: url('../../webfonts/3345536_4_0.eot');
	src: url('../../webfonts/3345536_4_0.eot?#iefix') format('embedded-opentype'),
			 url('../../webfonts/3345536_4_0.woff2') format('woff2'),
			 url('../../webfonts/3345536_4_0.woff') format('woff'),
			 url('../../webfonts/3345536_4_0.ttf') format('truetype');
}
@font-face {
	font-family: 'OptimaLTStd';
	font-weight: bold;
	font-style: italic;
	src: url('../../webfonts/3345536_3_0.eot');
	src: url('../../webfonts/3345536_3_0.eot?#iefix') format('embedded-opentype'),
			 url('../../webfonts/3345536_3_0.woff2') format('woff2'),
			 url('../../webfonts/3345536_3_0.woff') format('woff'),
			 url('../../webfonts/3345536_3_0.ttf') format('truetype');
}
*/
// All Fonts:
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/345536");

  
@font-face {font-family: 'OptimaLTStd-ExtraBlack';src: url('../../webfonts/345536_0_0.eot');src: url('../../webfonts/345536_0_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_0_0.woff2') format('woff2'),url('../../webfonts/345536_0_0.woff') format('woff'),url('../../webfonts/345536_0_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-DemiBold';src: url('../../webfonts/345536_1_0.eot');src: url('../../webfonts/345536_1_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_1_0.woff2') format('woff2'),url('../../webfonts/345536_1_0.woff') format('woff'),url('../../webfonts/345536_1_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-Medium';src: url('../../webfonts/345536_2_0.eot');src: url('../../webfonts/345536_2_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_2_0.woff2') format('woff2'),url('../../webfonts/345536_2_0.woff') format('woff'),url('../../webfonts/345536_2_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-BoldItalic';src: url('../../webfonts/345536_3_0.eot');src: url('../../webfonts/345536_3_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_3_0.woff2') format('woff2'),url('../../webfonts/345536_3_0.woff') format('woff'),url('../../webfonts/345536_3_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-Bold';src: url('../../webfonts/345536_4_0.eot');src: url('../../webfonts/345536_4_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_4_0.woff2') format('woff2'),url('../../webfonts/345536_4_0.woff') format('woff'),url('../../webfonts/345536_4_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-XBlackItalic';src: url('../../webfonts/345536_5_0.eot');src: url('../../webfonts/345536_5_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_5_0.woff2') format('woff2'),url('../../webfonts/345536_5_0.woff') format('woff'),url('../../webfonts/345536_5_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-Italic';src: url('../../webfonts/345536_6_0.eot');src: url('../../webfonts/345536_6_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_6_0.woff2') format('woff2'),url('../../webfonts/345536_6_0.woff') format('woff'),url('../../webfonts/345536_6_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-BlackItalic';src: url('../../webfonts/345536_7_0.eot');src: url('../../webfonts/345536_7_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_7_0.woff2') format('woff2'),url('../../webfonts/345536_7_0.woff') format('woff'),url('../../webfonts/345536_7_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-MediumItalic';src: url('../../webfonts/345536_8_0.eot');src: url('../../webfonts/345536_8_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_8_0.woff2') format('woff2'),url('../../webfonts/345536_8_0.woff') format('woff'),url('../../webfonts/345536_8_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-DemiBoldItalic';src: url('../../webfonts/345536_9_0.eot');src: url('../../webfonts/345536_9_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_9_0.woff2') format('woff2'),url('../../webfonts/345536_9_0.woff') format('woff'),url('../../webfonts/345536_9_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd-Black';src: url('../../webfonts/345536_A_0.eot');src: url('../../webfonts/345536_A_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_A_0.woff2') format('woff2'),url('../../webfonts/345536_A_0.woff') format('woff'),url('../../webfonts/345536_A_0.ttf') format('truetype');}
 
  
@font-face {font-family: 'OptimaLTStd';src: url('../../webfonts/345536_B_0.eot');src: url('../../webfonts/345536_B_0.eot?#iefix') format('embedded-opentype'),url('../../webfonts/345536_B_0.woff2') format('woff2'),url('../../webfonts/345536_B_0.woff') format('woff'),url('../../webfonts/345536_B_0.ttf') format('truetype');}
 