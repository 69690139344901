.signature {
	position: relative;
	float: left;
	top: 30px;
	margin-bottom: 30px;	

	img {
		max-width: 150px;
	}

	&:after {
		font-weight: 100;
		content: "Karl Kellenberger";
	}
}


.steckbrief {
	margin-top: 20px;
	margin-bottom: 30px;
	@include addfontsize (14);

	@include min-screen($defaultbreakpoint) {
		margin-top: 50px;
		margin-left: 50px;
		max-width: 400px;
	}

	h1 {
		@include addfontsizetitle(15);
		text-transform: uppercase;
	}
}