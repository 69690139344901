.blockquote {
    
    margin: 50px auto 100px auto;
    padding: 30px 0;
    width: 75%;
    position: relative;

    @include min-screen($sm) {
        margin: 80px auto 200px auto;
        padding: 30px 0;
        width: 70%;
    }
    @include min-screen($md) {
        margin: 80px auto 200px auto;
        padding: 30px 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 66.666%;
      left: 50%;
      height: 1px;
      background-color: #ddd;
    }
    
    &__content {
        font-family: "Georgia", "Times New Roman", serif;
        font-style: italic;
        font-size: 18px;
        color: #000;
        top: 0;
        padding: 0;
        text-align: left;

        @include min-screen($md) {
            font-size: 24px;
        }
        
        &:before {
            position: absolute;
            left: -40px;
            top: -10px;
            content: '\201C';
            font-size: 80px;
            opacity: 0.75;
            padding-right: 10px;

            @include min-screen($sm) {
                left: -60px;
                top: -20px;
                font-size: 100px;
            }

        }
    }
    
    &__author {
        margin-top: 10px;
        color: rgb(120, 120, 120);
        font-size: 14px;
        text-align: right;
        
        &:before {
            content: ' – ';
            //margin-right: 15px;
        }
    }
}