%paragraph-stories {

}

//Story Container
.stories {
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(wrap);

	margin-top: 25px;

    &-single {
        @include flex(1 1 100%);
        margin: 0;

        @include min-screen($defaultbreakpoint) {
        	@include flex(1 0 45%);
        }
    }
}

//styling of one story
.story {
	margin: 0 15px;

	&__image {

	}

    &__text {
    	position: relative;
	    background-color: #ffffff;

        @include min-screen($defaultbreakpoint) {
                top: -45px;
                margin-left: 50px;
                padding: 20px 30px 10px 25px;
        }

	    h2 {
	    	margin: 0;
	    }

        &--clamped {
            //check mixin
        }
    }
}