.cv {

  display: none;

  @include min-screen($lg) {
    display: block;
  }

}

.vis-timeline {
  border-width: 0 1px !important;
  @include transition(height 500ms ease-in-out);
  max-height: 80vh;
  visibility: visible !important;
}

.vis-content {
  padding: 2rem 0;
}

.cv-event {

  &__date {
    display: block;
    //margin-bottom: 0.25rem;
    color: #797979;
    font-size: 0.8rem !important;
    font-weight: bold;

    * {
      font-size: inherit !important;
    }
  }
  /*&__startdate,
  &__enddate {

  }*/

  &__title {
    margin: 0 0 0.2rem 0;
    font-weight: bold;
    line-height: 1.25;
  }

  &__text {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //min-width: 5rem;
    //max-width: 10rem;
    //max-width: 400px;
  }
}

.vis-item {
  background-color: transparent !important;
  border: 1PX solid transparent !important;
  @include transition(all 500ms ease-in-out);
  //min-width: 3rem !important;
  //max-width: 10rem !important;

  //width: auto !important;

  &:hover {
    opacity: 1 !important;
  }

  &:before {
    content: '';
    display: block;
    height: 200vh;
    top: 50%;
    width: 1px;
    position: absolute;
    left: 5px;
    //background-color: transparent;
    @include transition(background-color 500ms ease-in-out);
  }

  &.vis-box {
    text-align: left !important;
  }

  .vis-item-content {
    @include transition(width 500ms ease-in-out);
    //white-space: normal !important;
  }

}

 .vis-item__inner {

   cursor: pointer;
   background-color: transparent !important;
   //background-color: rgb(240, 240, 240) !important;
   border: 1PX solid transparent !important;
   border-radius: 6px;
   padding: 5px;
   @include transition(
                   font-size 500ms ease-in-out,
                   background-color 500ms ease-in-out,
                   border-color 500ms ease-in-out
   );

   &:hover {
     border-color: #d8d8d8 !important;
   }

  // selected
   @at-root {
     .vis-item {
       &.vis-selected {

         width: 20% !important;
         @include transform(scale(1.5));
         transform-origin: bottom left;

         .vis-item-overflow {
           overflow: visible !important;
         }

         .vis-item-content {
           width: auto !important;

           .cv-event__text {
             white-space: normal !important;
             font-size: 0.75rem;
           }
         }

         &:before {
           background-color: #C6C6C6;
         }

         .vis-item__inner {
           background-color: #fff !important;
           border-color: #C6C6C6 !important;
         }
       }
     }
   }


   .vis-item-overflow {
     overflow: visible !important;
   }


   * {
     transition: all 500ms ease-in-out;
     font-size: 1rem;
   }
   .cv-event__text {
     font-size: 3px;
     text-overflow: ellipsis;
     overflow: hidden;
   }
   .cv-event__text,
   .cv-event__title {
     text-overflow: ellipsis;
     overflow: hidden;
   }

 }

 #visualization {
   &.zoomed {
     .vis-item {
       &:not(.vis-selected) {
         //filter: blur(5px);
         opacity: 0.4;
         @include transform(scale(0.75));
       }
     }
   }
 }

/*#visualization {
 &.zoomed {
   .vis-item {

     //border-color: #C6C6C6 !important;

     * {
       font-size: 16px;
     }

     &.vis-selected {
        //background-color: #fff !important;
       z-index: 2;

     }

     &:hover {
       z-index: 2;

       .vis-item__inner {
         background-color: #fff !important;
       }

       .vis-item-overflow {
         overflow: visible !important;
       }


       .cv-event__text,
       .cv-event__title {
         overflow: visible;
       }
     }

     &__inner {
       min-width: 200px;
       //max-width: 400px;
     }
     .cv-event__text,
     .cv-event__title {
       font-size: 16px;
       white-space: normal !important;
     }
   }
 }


}
*/

// axis
.vis-time-axis .vis-grid.vis-minor,
.vis-time-axis .vis-grid.vis-major {
  border-color: #fff !important;
}



.cv-event-categories {

  position: absolute;
  z-index: 666;
  left: 1PX;
  top: 1PX;
  padding: 15px;
  @include transition(all 0.2s ease-in-out);
  background-color: transparent;

  &:hover {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

}
.cv-event-category {

  margin: 0 !important;
  list-style: none !important;

  &__radio {
    display: none;
    visibility: hidden;
    height: 0;

    &:checked {
      + .cv-event-category__name {
        &:before {
          background-color: currentColor;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__name {
    position: relative;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.9rem;
    padding-left: 30px;

    &:before {
      position: absolute;
      background-color: transparent;
      content: '';
      left: 3px;
      top: 0;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 2px solid currentColor;
      transition: all 250ms ease-in-out;
    }

    &:after {
      opacity: 0;
      position: absolute;
      left: 6px;
      top: 3px;
      content: '✔';
      font-size: 0.8rem;
      line-height: 1;
      color: #fff;
      transition: all 250ms ease-in-out;
    }
  }
}


// zoom out button
#eventTimelineZoomOutButton {
  position: absolute;
  z-index: 666;
  top: 15px;
  border: none;
  right: 15px;
  font-size: 0.8rem;
  border-radius: 100px;
  background-color: #EFEFEF;
  font-weight: bold;
  padding: 0.35rem 0.85rem;
  opacity: 0;
  @include transition(all 0.2s ease-in-out);

  &:hover {
    background-color: #C6C6C6;
  }
  &:active {
    color: #000;
  }

  &.zoomed {
    opacity: 1;
  }
}
