html {
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;

	//-moz-osx-font-smoothing: grayscale;

    -ms-touch-action: none !important;

	text-rendering: optimizeLegibility;
	font-feature-settings: "kern";
	-webkit-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern";
	-moz-font-feature-settings: "kern=1";

	@include css3-prefix(hyphens, auto);
}




body {

	font-family: $font-family--sans-serif;
	line-height: $line-height-base;
	color: #000;
	background-color: #fff;

	-webkit-overflow-scrolling: touch;


	font-size: 18px;
	line-height: 1.65;
}



// Reset fonts for relevant elements
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;

	&:active,
	&:focus {
		outline: none;
	}
}

figure {
	margin: 0;
}

button {
    cursor: pointer;
}

// Links
a {
	color: $link-color;
	@include transition($transition-duration color $transition-timing);

	&:hover {
		color: inherit;
		text-decoration: none;
		outline: none;
	}

	&:focus,
	&:active {
		color: inherit;
		text-decoration: none;
		outline: none;
	}
}

// Images
img {
	vertical-align: middle;
	@include responsive-image();
}

hr {
	display: block;
	width: 80%;
    height: 1px;
    border-top: 1px solid #dddddd;
    padding: 0; 
    margin: 40px auto;
}

h1,
h2,
h3 {
	font-family: $font-family--titles;
	//line-height: 1.1;
}
