//HEADER
.main-header-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 666;
    background-color: #fff;
    @include transition($transition-duration box-shadow $transition-timing);

    @include min-screen($md){

    }
  
    &--shadowed {
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    }
}

.main-header {
    
    margin: 0 auto;
    padding: 0 15px;
    
    @include flexbox();
    @include flex-wrap(nowrap);
    @include justify-content(space-between);
    
    @include min-screen($md){
        padding: 0;
        max-width: (4 / 5) * $max-element-width;
    }
    
    &__logo-wrapper {
        @include flex(0 0 120px);
        @include flexbox();
    }
    
    &__logo-link {
        @include flex(1 1 auto);
        @include align-self(center);
        display: block;
        padding: 10px 0;
    }
    
    &__navigation-wrapper {
        @include flex(0 0 auto);
        
        @include flexbox();
        @include flex-wrap(nowrap);
        
        @include min-screen($md){
            @include flex-wrap(wrap);
            @include flex(1 1 auto);
        }
        
    }
    
    &__language-selector-wrapper {
        
        display: none; 
    
        @include min-screen($md) {
            @include flex(0 0 100%);
            margin-left: auto;
            padding-top: 10px;
        
            @include flexbox();
        }

    }
    
    &__search {
        @include flex(0 0 30px);
        @include align-self(center);
    }
    
    &__hamburger {
        @include flex(0 0 44px);
        @include align-self(center);
        margin-left: 15px;
        
        @include min-screen($md){
            display: none;
        }
    }

    
}

