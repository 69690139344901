.competences {
    @include flexbox();
    @include flex-direction(column);
    @include justify-content(space-between);

    &-single {
        @include flex(1 1 50%);
    }
}


//Styling of a competence
.competence {
	h2 {
		margin: 0;
	}
	p {
		margin: 0 0 20px 0;
	}
}