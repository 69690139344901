.logo {

    &__image {
    	margin: 5px 10px;
    	height: 40px;
    	width: auto;
        float: right;

    	@include min-screen($defaultbreakpoint) {
    		height: 80px;
            float: left;
    		margin: 0px;
    	}
    }

    &__link {
        display: block;
    }
}