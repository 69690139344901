.footer {
    padding: 20px 10px;
    color: $footer-color;
    text-align: center;

    @include min-screen($defaultbreakpoint) {
        max-width: $max-page-width;
    	margin: 30px $margin-right--desktop 20px $margin-left--desktop;;
   	}

    &__contact {
    	font-size: 10px;

    	@include min-screen($sm) {
	        font-size: 12px;
	    }

	    @include min-screen($defaultbreakpoint) {
	    	font-size: 14px;
	    }
    }

    a {
    	color: $footer-color;
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
}