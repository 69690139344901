
a {

	&:hover {
		text-decoration: none;
		outline: none;
	}

	&:focus,
	&:active {
		text-decoration: none;
		outline: none;
	}
}



ol,
ul {
	@include unstyled-list();
}



.main {
    
    padding: 20px 0;
    
}



.title {
    @include css3-prefix(hyphens, none);

    &--first {
        @extend %title--first;
    }

    &--secondary {
        @extend %title--second;
    }

    &--third {
        @extend %title--third;
    }

	&--fourth {
        @extend %title--fourth;
	}

	&--centered {
    	text-align: center;
	}
	
	&--sans-serif {
    	font-family: $font-family--sans-serif;
	}
	
	&--uppercase {
    	text-transform: uppercase;
	}
	
	span {
		font-weight: normal;
	}
	
}



.link {
    
    &--cta {

    	a {
	        display: block;
	        padding: 5px 8px;
	        text-transform: uppercase;
	        font-weight: bold;
	        text-decoration: none;
	    }
        
    }

}
