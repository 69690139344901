.wpml-ls {
    
}

.language-selector {

	line-height: 1.3;
	    a {
	    	text-decoration: none;
	    	font-size: 12px;
	    	@include transition($transition-duration color $transition-timing);
        
	        &:hover {
	            color: $nav-link__color--hover;
	        }
	    }
}