$font-family--sans-serif:   Arial, sans-serif;
$font-family--serif:       Georgia, serif;
$font-family--titles:	OptimaLTStd, Arial, sans-serif;
$line-height-base:				 6.3;
$line-height--heading:              1.1;

$heading2-marginbottom: 25px;

$link-color:						   #000;
$link-color--hover:				 inherit;

$nav-width: 200px;

//Colorschema - Navigation
//Colorschema
$nav-color--primary: rgba(255, 255, 255, 0.8);
$nav-color--mobile: rgb(0, 43, 184);
$nav-color--hover: #052080;

//Variables Nav Specific
$nav-link__color--primary:  #000000;
$nav-link__color--secondary:#ffffff;
$nav-link__color--hover:    #B2B2B2;

//Footer
$footer-color: #BBBBBB;


// Layout
$max-page-width:				   1140px;
$max-element-width:                 $max-page-width;

$margin-left--desktop:		250px;
$margin-right--desktop:		60px;

$margin-left--tablet:		200px;
$margin-right--tablet:		60px;

$max-paragraph-width:			900px;

// «Grid» Breakpoints
$xs:							480px;
$sm:							768px;
$md:							992px;
$lg:							1200px;
$xlg:                           1850px;

// Standard-Mobile Breakpoint
$defaultbreakpoint: $sm;


// Transition settings
$transition-duration:      250ms;
$transition-timing:        ease-in-out;


$hover--hover:  hover;