.navigation {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-evenly);
    position: fixed;
    top: 0;
    width: 100vw;
    height: 50px;
    z-index: 69;
    /*@include linear-gradient(
        -90deg
        , rgba(0, 43, 200, 1)
        , rgba(0, 43, 184, 1)
    );*/
  background-color: $nav-color--hover;
        
    //Desktop Navigation
    @include min-screen($defaultbreakpoint) {
        @include flex-direction(column);
        background-image: none;
        background-color: $nav-color--primary;
        top: 0;
        bottom: 0;
        left: 0;
        width: $nav-width;
        height: 100%;
        padding: 30px;
    }

    &:after {
        content: "";
        position: fixed;
        //@include linear-gradient-navigation();
        //background-size: 300% 300%;
      /*@include linear-gradient(
                      -18deg
              , rgba(19, 53, 212, 0.6)
              , rgba(4, 33, 167, 0.6)
      );*/
      background-color: $nav-color--hover;
        //@include animation(4s gradient 0s infinite);
        top: 0;
        bottom: 0;
        left: 0;
        width: $nav-width;
        height: 100%;
        opacity: 0;
        @include transition(opacity $transition-duration $transition-timing);
        z-index: -1;
    }

    &:hover {

        .menu-item {
            a {
                color: $nav-link__color--secondary;
            }
        }
        a {
            color: $nav-link__color--secondary;
        }

        //show navigation
        @include min-screen($defaultbreakpoint) {
            &:after {
                opacity: 1;
            }
        }

        .current_page_item {
            a {
                &:before {
                    background: #fff !important;
                }
            }
        }
    }
    
    &__hamburger {
        @include flex(1 1 80%);

        @include min-screen($defaultbreakpoint) {
            display: none;
        }
    }
    
    &__logo {
        @include flex(1 1 20%);
    }
    
    // navigation list
    &__list {
        display: none;

        @include min-screen($defaultbreakpoint) {
            @include flex(1 1 65%);
            display: block;
        }


        &--mobile {
            display: block;

            width: 100vw;
            height: 40vh;
            margin-top: 50px;
            padding-top: 10vh;

            .menu-item {

                text-align: center;

                a {

                }
            }
        }

    }

    .menu-item {
        font-size: 30px;
        font-weight: bold;
        margin-top: 5px;
        position: relative;

        @include min-screen($defaultbreakpoint) {
            font-size: 22px;
        }

        a {
            color: $nav-link__color--primary;
            position: relative;
            text-decoration: none;
            padding: 3px 0;

            //Animate text underline hover with a linear-gradient
            &:after
            {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 1px;
                background: #fff;
                content: '';
                opacity: 0;
                @include transition(height $transition-duration, opacity $transition-duration, transform $transition-duration);
                -webkit-transform: translateY(-7px);
                -moz-transform: translateY(-7px);
                transform: translateY(-7px);
            }

            &:hover::after,
            &:focus::after {
                height: 3px;
                opacity: 1;
                -webkit-transform: translateY(0px);
                -moz-transform: translateY(0px);
                transform: translateY(0px);
            }
        }

        &.current_page_item {
            a {
                &:before {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background: #000;
                    @include transition(background $transition-duration $transition-timing);
                    content: '';
                }
            }

            &:after,
            a:after {
                background: none;
            }
        }
    }


    &__impressum {
        position: relative;
        display: none;

        @include min-screen($defaultbreakpoint) {
            @include flex(1 1 5%);
            display: block;
        }

        &--mobile {
            display: block;
            position: absolute;
            width: 100vw;
            bottom: 30%;
            text-align: center;
        }
    }
    
    &__language-selector {
        position: relative;
        display: none;

        @include min-screen($defaultbreakpoint) {
            @include flex(1 1 10%);
            display: block;
            border: none;
        }

        &--mobile {
            display: block;
            position: absolute;
            width: 100vw;
            bottom: 20%;
            text-align: center;
        }
    }

}

.mobile-nav {
    display: none;
    position: fixed;
    top: 50px;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    /*@include linear-gradient(
                -18deg
                , rgb(0, 60, 255)
                , rgb(0, 43, 184)
                );*/
  background-color: $nav-color--hover;
    a {
        color: #ffffff;
    }
}

#toggle-menu {
    display: none;
}

#toggle-menu[type="checkbox"]:checked ~ .mobile-nav {
    display: block;
}

//animation for hover
/*@include keyframes( gradient )
{
    0% {
        background-size: 300% 300%;
    }

    50% {
        background-size: 100% 100%;
    }

    100% {
        background-size: 300% 300%;
    }
}*/