.services {
	@include flexbox();
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include justify-content(space-between);

    margin-top: 30px;

	&__single {
    	@include flex(1 1 70%);

    	@include min-screen($xs) {
			@include flex(0 1 70%);
		}

    	@include min-screen($defaultbreakpoint+1) {
			@include flex(1 1 25%);
		}
	}
}

.service {
   		margin: 15px 0;
		padding: 20px 0;
		//border-right: solid 1px #eeeeee;
		//border-left: solid 1px #eeeeee;

		&:last-of-type {
			border-right: none;
		}

		&__thumbnail {
			@include flexbox();
	    	@include flex-direction(column);
	    	@include justify-content(center);
    		@include flex-wrap(wrap);
    		//height: 130px;
			margin-bottom: 40px;
    	
	    	/*@include min-screen($defaultbreakpoint+1) {
	    		height: 180px;
			}*/

			img {
				//margin: auto;
              display: block;
				max-width: 75%;
				//max-height: 180px;
				//max-width: 180px;
				@include min-screen($defaultbreakpoint+1) {
		    		//max-height: 130px;
					//max-width: 130px;
				}
			}
		}

		&__content {
			ul {
				@include addfontsize(14);
			}
			h2 {
				@include addfontsizetitle(18);
				margin-bottom: 5px;
			}
		}
}