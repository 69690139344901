.page-title {
    display: none;

    //Desktop
    @include min-screen($defaultbreakpoint) {
        display: block;
        max-width: $max-page-width;
        margin: 30px $margin-right--desktop 30px $margin-left--desktop;

        text-transform: uppercase;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
    }
}