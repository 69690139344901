@charset "UTF-8";
/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
@import url("//hello.myfonts.net/count/345536");
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px;
}

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.title--first, .simple-page__content__title h1, .simple-page__intro-content h1, .simple-page__wysiwyg-content h1 {
  font-family: OptimaLTStd, Arial, sans-serif;
  font-size: 30px;
  margin-bottom: 15px;
  line-height: 1.2;
  font-weight: 900;
  -webkit-hyphens: none;
  -khtml-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
}

@media only screen and (min-width: 992px) {
  .title--first, .simple-page__content__title h1, .simple-page__intro-content h1, .simple-page__wysiwyg-content h1 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 1200px) {
  .title--first, .simple-page__content__title h1, .simple-page__intro-content h1, .simple-page__wysiwyg-content h1 {
    font-size: 36px;
  }
}

.title--first span, .simple-page__content__title h1 span, .simple-page__intro-content h1 span, .simple-page__wysiwyg-content h1 span {
  font-weight: 100;
}

.title--secondary, .simple-page__content__title h2, .simple-page__intro-content h2, .simple-page__wysiwyg-content h2 {
  font-family: OptimaLTStd, Arial, sans-serif;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
  -webkit-hyphens: none;
  -khtml-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
}

@media only screen and (min-width: 992px) {
  .title--secondary, .simple-page__content__title h2, .simple-page__intro-content h2, .simple-page__wysiwyg-content h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .title--secondary, .simple-page__content__title h2, .simple-page__intro-content h2, .simple-page__wysiwyg-content h2 {
    font-size: 22px;
  }
}

.title--third {
  font-family: OptimaLTStd, Arial, sans-serif;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  -webkit-hyphens: none;
  -khtml-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
}

@media only screen and (min-width: 992px) {
  .title--third {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) {
  .title--third {
    font-size: 20px;
  }
}

.title--fourth {
  font-family: OptimaLTStd, Arial, sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
  -webkit-hyphens: none;
  -khtml-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
}

@media only screen and (min-width: 992px) {
  .title--fourth {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) {
  .title--fourth {
    font-size: 18px;
  }
}

.cta, .simple-page__wysiwyg-content p, .simple-page__wysiwyg-content ol,
.simple-page__wysiwyg-content ul {
  font-size: 16px;
}

@media only screen and (min-width: 992px) {
  .cta, .simple-page__wysiwyg-content p, .simple-page__wysiwyg-content ol,
  .simple-page__wysiwyg-content ul {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1200px) {
  .cta, .simple-page__wysiwyg-content p, .simple-page__wysiwyg-content ol,
  .simple-page__wysiwyg-content ul {
    font-size: 20px;
  }
}

/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
/* Display definitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  /* 1 */
  display: block;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none;
}

/* Elements of HTML (https://www.w3.org/TR/html5/semantics.html)
   ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: inherit;
  /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritence in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Add the default cursor in all browsers (opinionated).
 * 3. Add a flattened line height in all browsers (opinionated).
 * 4. Prevent font size adjustments after orientation changes in IE and iOS.
 */
html {
  box-sizing: border-box;
  /* 1 */
  cursor: default;
  /* 2 */
  font-family: sans-serif;
  /* 3 */
  line-height: 1.5;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 4 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
}

/* Sections (https://www.w3.org/TR/html5/sections.html)
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font sizes and margins on `h1` elements within
 * `section` and `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: .67em 0;
}

/* Grouping content (https://www.w3.org/TR/html5/grouping-content.html)
   ========================================================================== */
/**
 * 1. Correct font sizing inheritance and scaling in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * 1. Correct the height in Firefox.
 * 2. Add visible overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Add a bordered underline effect in all browsers.
 * 2. Remove text decoration in Firefox 40+.
 */
abbr[title] {
  border-bottom: 1px dotted;
  /* 1 */
  text-decoration: none;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct colors in IE 9-.
 */
mark {
  background-color: #ffff00;
  color: #000000;
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}

/**
 * Correct the font size in all browsers.
 */
small {
  font-size: 83.3333%;
}

/**
 * Change the positioning on superscript and subscript elements
 * in all browsers (opinionated).
 * 1. Correct the font size in all browsers.
 */
sub,
sup {
  font-size: 83.3333%;
  /* 1 */
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

/*
 * Remove the text shadow on text selections (opinionated).
 * 1. Restore the coloring undone by defining the text shadow (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000000;
  /* 1 */
  text-shadow: none;
}

/* Embedded content (https://www.w3.org/TR/html5/embedded-content-0.html)
   ========================================================================== */
/*
 * Change the alignment on media elements in all browers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Links (https://www.w3.org/TR/html5/links.html#links)
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove the gaps in underlines in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * Remove the outline when hovering in all browsers (opinionated).
 */
a:hover {
  outline-width: 0;
}

/* Tabular data (https://www.w3.org/TR/html5/tabular-data.html)
   ========================================================================== */
/*
 * Remove border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* transform-style:  (https://www.w3.org/TR/html5/forms.html)
   ========================================================================== */
/**
 * 1. Remove the default styling in all browsers (opinionated).
 * 3. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  background-color: transparent;
  /* 1 */
  border-style: none;
  /* 1 */
  color: inherit;
  /* 1 */
  font-size: 1em;
  /* 1 */
  margin: 0;
  /* 3 */
}

/**
 * Correct the overflow in IE.
 * 1. Correct the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance in Edge, Firefox, and IE.
 * 1. Remove the inheritance in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent the WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Correct the focus styles unset by the previous rule.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the border, margin, and padding in all browsers.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Remove the vertical scrollbar in IE.
 * 2. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  overflow: auto;
  /* 1 */
  resize: vertical;
  /* 2 */
}

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * Correct the cursor style on increment and decrement buttons in Chrome.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari for OS X.
 */
::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Correct the text style on placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* WAI-ARIA (https://www.w3.org/TR/html5/dom.html#wai-aria)
   ========================================================================== */
/**
 * Change the cursor on busy elements (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements (opinionated).
 */
[aria-disabled] {
  cursor: default;
}

/* User interaction (https://www.w3.org/TR/html5/editing.html)
   ========================================================================== */
/*
 * Remove the tapping delay on clickable elements (opinionated).
 * 1. Remove the tapping delay in IE 10.
 */
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
}

/*
 * Change the display on visually hidden accessible elements (opinionated).
 */
[hidden][aria-hidden="false"] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden="false"]:focus {
  clip: auto;
}

/*
* OptimaLTStd font

@font-face {
	font-family: 'OptimaLTStd';
	font-weight: normal;
	font-style: normal;
	src: url('../../webfonts/3345536_B_0.eot');
	src: url('../../webfonts/3345536_B_0.eot?#iefix') format('embedded-opentype'),
			 url('../../webfonts/3345536_B_0.eot.woff2') format('woff2'),
			 url('../../webfonts/3345536_B_0..woff') format('woff'),
			 url('../../webfonts/3345536_B_0..ttf') format('truetype');
}
@font-face {
	font-family: 'OptimaLTStd';
	font-weight: normal;
	font-style: italic;
	src: url('../../webfonts/3345536_6_0.eot');
	src: url('../../webfonts/3345536_6_0.eot?#iefix') format('embedded-opentype'),
			 url('../../webfonts/3345536_6_0.woff2') format('woff2'),
			 url('../../webfonts/3345536_6_0.woff') format('woff'),
			 url('../../webfonts/3345536_6_0.ttf') format('truetype');
}
@font-face {
	font-family: 'OptimaLTStd';
	font-weight: bold;
	font-style: normal;
	src: url('../../webfonts/3345536_4_0.eot');
	src: url('../../webfonts/3345536_4_0.eot?#iefix') format('embedded-opentype'),
			 url('../../webfonts/3345536_4_0.woff2') format('woff2'),
			 url('../../webfonts/3345536_4_0.woff') format('woff'),
			 url('../../webfonts/3345536_4_0.ttf') format('truetype');
}
@font-face {
	font-family: 'OptimaLTStd';
	font-weight: bold;
	font-style: italic;
	src: url('../../webfonts/3345536_3_0.eot');
	src: url('../../webfonts/3345536_3_0.eot?#iefix') format('embedded-opentype'),
			 url('../../webfonts/3345536_3_0.woff2') format('woff2'),
			 url('../../webfonts/3345536_3_0.woff') format('woff'),
			 url('../../webfonts/3345536_3_0.ttf') format('truetype');
}
*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'OptimaLTStd-ExtraBlack';
  src: url("../../webfonts/345536_0_0.eot");
  src: url("../../webfonts/345536_0_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_0_0.woff2") format("woff2"), url("../../webfonts/345536_0_0.woff") format("woff"), url("../../webfonts/345536_0_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-DemiBold';
  src: url("../../webfonts/345536_1_0.eot");
  src: url("../../webfonts/345536_1_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_1_0.woff2") format("woff2"), url("../../webfonts/345536_1_0.woff") format("woff"), url("../../webfonts/345536_1_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-Medium';
  src: url("../../webfonts/345536_2_0.eot");
  src: url("../../webfonts/345536_2_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_2_0.woff2") format("woff2"), url("../../webfonts/345536_2_0.woff") format("woff"), url("../../webfonts/345536_2_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-BoldItalic';
  src: url("../../webfonts/345536_3_0.eot");
  src: url("../../webfonts/345536_3_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_3_0.woff2") format("woff2"), url("../../webfonts/345536_3_0.woff") format("woff"), url("../../webfonts/345536_3_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-Bold';
  src: url("../../webfonts/345536_4_0.eot");
  src: url("../../webfonts/345536_4_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_4_0.woff2") format("woff2"), url("../../webfonts/345536_4_0.woff") format("woff"), url("../../webfonts/345536_4_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-XBlackItalic';
  src: url("../../webfonts/345536_5_0.eot");
  src: url("../../webfonts/345536_5_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_5_0.woff2") format("woff2"), url("../../webfonts/345536_5_0.woff") format("woff"), url("../../webfonts/345536_5_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-Italic';
  src: url("../../webfonts/345536_6_0.eot");
  src: url("../../webfonts/345536_6_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_6_0.woff2") format("woff2"), url("../../webfonts/345536_6_0.woff") format("woff"), url("../../webfonts/345536_6_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-BlackItalic';
  src: url("../../webfonts/345536_7_0.eot");
  src: url("../../webfonts/345536_7_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_7_0.woff2") format("woff2"), url("../../webfonts/345536_7_0.woff") format("woff"), url("../../webfonts/345536_7_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-MediumItalic';
  src: url("../../webfonts/345536_8_0.eot");
  src: url("../../webfonts/345536_8_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_8_0.woff2") format("woff2"), url("../../webfonts/345536_8_0.woff") format("woff"), url("../../webfonts/345536_8_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-DemiBoldItalic';
  src: url("../../webfonts/345536_9_0.eot");
  src: url("../../webfonts/345536_9_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_9_0.woff2") format("woff2"), url("../../webfonts/345536_9_0.woff") format("woff"), url("../../webfonts/345536_9_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd-Black';
  src: url("../../webfonts/345536_A_0.eot");
  src: url("../../webfonts/345536_A_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_A_0.woff2") format("woff2"), url("../../webfonts/345536_A_0.woff") format("woff"), url("../../webfonts/345536_A_0.ttf") format("truetype");
}

@font-face {
  font-family: 'OptimaLTStd';
  src: url("../../webfonts/345536_B_0.eot");
  src: url("../../webfonts/345536_B_0.eot?#iefix") format("embedded-opentype"), url("../../webfonts/345536_B_0.woff2") format("woff2"), url("../../webfonts/345536_B_0.woff") format("woff"), url("../../webfonts/345536_B_0.ttf") format("truetype");
}

.main,
.footer-wrapper {
  position: relative;
}

.main {
  background-color: #fff;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-touch-action: none !important;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern=1";
  -webkit-hyphens: auto;
  -khtml-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto;
}

body {
  font-family: Arial, sans-serif;
  line-height: 6.3;
  color: #000;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
  font-size: 18px;
  line-height: 1.65;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

input:active, input:focus,
button:active,
button:focus,
select:active,
select:focus,
textarea:active,
textarea:focus {
  outline: none;
}

figure {
  margin: 0;
}

button {
  cursor: pointer;
}

a {
  color: #000;
  -webkit-transition: 250ms color ease-in-out;
  -khtml-transition: 250ms color ease-in-out;
  -moz-transition: 250ms color ease-in-out;
  -ms-transition: 250ms color ease-in-out;
  -o-transition: 250ms color ease-in-out;
  transition: 250ms color ease-in-out;
}

a:hover {
  color: inherit;
  text-decoration: none;
  outline: none;
}

a:focus, a:active {
  color: inherit;
  text-decoration: none;
  outline: none;
}

img {
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  display: block;
  height: auto;
}

hr {
  display: block;
  width: 80%;
  height: 1px;
  border-top: 1px solid #dddddd;
  padding: 0;
  margin: 40px auto;
}

h1,
h2,
h3 {
  font-family: OptimaLTStd, Arial, sans-serif;
}

.main-header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 666;
  background-color: #fff;
  -webkit-transition: 250ms box-shadow ease-in-out;
  -khtml-transition: 250ms box-shadow ease-in-out;
  -moz-transition: 250ms box-shadow ease-in-out;
  -ms-transition: 250ms box-shadow ease-in-out;
  -o-transition: 250ms box-shadow ease-in-out;
  transition: 250ms box-shadow ease-in-out;
}

.main-header-wrapper--shadowed {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.main-header {
  margin: 0 auto;
  padding: 0 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

@media only screen and (min-width: 992px) {
  .main-header {
    padding: 0;
    max-width: 912px;
  }
}

.main-header__logo-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 120px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 120px;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.main-header__logo-link {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  display: block;
  padding: 10px 0;
}

.main-header__navigation-wrapper {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -moz-box-flex: 0;
  -moz-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}

@media only screen and (min-width: 992px) {
  .main-header__navigation-wrapper {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

.main-header__language-selector-wrapper {
  display: none;
}

@media only screen and (min-width: 992px) {
  .main-header__language-selector-wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin-left: auto;
    padding-top: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.main-header__search {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 30px;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.main-header__hamburger {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 44px;
  -moz-box-flex: 0;
  -moz-flex: 0 0 44px;
  -ms-flex: 0 0 44px;
  flex: 0 0 44px;
  -webkit-align-self: center;
  -moz-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 15px;
}

@media only screen and (min-width: 992px) {
  .main-header__hamburger {
    display: none;
  }
}

.logo__image {
  margin: 5px 10px;
  height: 40px;
  width: auto;
  float: right;
}

@media only screen and (min-width: 768px) {
  .logo__image {
    height: 80px;
    float: left;
    margin: 0px;
  }
}

.logo__link {
  display: block;
}

@media only screen and (min-width: 768px) {
  .hamburger {
    display: none;
  }
}

.hamburger .hamburger-inner {
  background-color: #fff;
}

.hamburger .hamburger-inner:before, .hamburger .hamburger-inner:after {
  background-color: #fff;
}

.language-selector {
  line-height: 1.3;
}

.language-selector a {
  text-decoration: none;
  font-size: 12px;
  -webkit-transition: 250ms color ease-in-out;
  -khtml-transition: 250ms color ease-in-out;
  -moz-transition: 250ms color ease-in-out;
  -ms-transition: 250ms color ease-in-out;
  -o-transition: 250ms color ease-in-out;
  transition: 250ms color ease-in-out;
}

.language-selector a:hover {
  color: #B2B2B2;
}

.navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -moz-justify-content: space-evenly;
  justify-content: space-evenly;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 50px;
  z-index: 69;
  /*@include linear-gradient(
        -90deg
        , rgba(0, 43, 200, 1)
        , rgba(0, 43, 184, 1)
    );*/
  background-color: #052080;
}

@media only screen and (min-width: 768px) {
  .navigation {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-image: none;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    height: 100%;
    padding: 30px;
  }
}

.navigation:after {
  content: "";
  position: fixed;
  /*@include linear-gradient(
                      -18deg
              , rgba(19, 53, 212, 0.6)
              , rgba(4, 33, 167, 0.6)
      );*/
  background-color: #052080;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 100%;
  opacity: 0;
  -webkit-transition: opacity 250ms ease-in-out;
  -khtml-transition: opacity 250ms ease-in-out;
  -moz-transition: opacity 250ms ease-in-out;
  -ms-transition: opacity 250ms ease-in-out;
  -o-transition: opacity 250ms ease-in-out;
  transition: opacity 250ms ease-in-out;
  z-index: -1;
}

.navigation:hover .menu-item a {
  color: #ffffff;
}

.navigation:hover a {
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .navigation:hover:after {
    opacity: 1;
  }
}

.navigation:hover .current_page_item a:before {
  background: #fff !important;
}

.navigation__hamburger {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 80%;
  -moz-box-flex: 1;
  -moz-flex: 1 1 80%;
  -ms-flex: 1 1 80%;
  flex: 1 1 80%;
}

@media only screen and (min-width: 768px) {
  .navigation__hamburger {
    display: none;
  }
}

.navigation__logo {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 20%;
  -moz-box-flex: 1;
  -moz-flex: 1 1 20%;
  -ms-flex: 1 1 20%;
  flex: 1 1 20%;
}

.navigation__list {
  display: none;
}

@media only screen and (min-width: 768px) {
  .navigation__list {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 65%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 65%;
    -ms-flex: 1 1 65%;
    flex: 1 1 65%;
    display: block;
  }
}

.navigation__list--mobile {
  display: block;
  width: 100vw;
  height: 40vh;
  margin-top: 50px;
  padding-top: 10vh;
}

.navigation__list--mobile .menu-item {
  text-align: center;
}

.navigation .menu-item {
  font-size: 30px;
  font-weight: bold;
  margin-top: 5px;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .navigation .menu-item {
    font-size: 22px;
  }
}

.navigation .menu-item a {
  color: #000000;
  position: relative;
  text-decoration: none;
  padding: 3px 0;
}

.navigation .menu-item a:after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  content: '';
  opacity: 0;
  -webkit-transition: height 250ms, opacity 250ms, transform 250ms;
  -khtml-transition: height 250ms, opacity 250ms, transform 250ms;
  -moz-transition: height 250ms, opacity 250ms, transform 250ms;
  -ms-transition: height 250ms, opacity 250ms, transform 250ms;
  -o-transition: height 250ms, opacity 250ms, transform 250ms;
  transition: height 250ms, opacity 250ms, transform 250ms;
  -webkit-transform: translateY(-7px);
  -moz-transform: translateY(-7px);
  transform: translateY(-7px);
}

.navigation .menu-item a:hover::after, .navigation .menu-item a:focus::after {
  height: 3px;
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

.navigation .menu-item.current_page_item a:before {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background: #000;
  -webkit-transition: background 250ms ease-in-out;
  -khtml-transition: background 250ms ease-in-out;
  -moz-transition: background 250ms ease-in-out;
  -ms-transition: background 250ms ease-in-out;
  -o-transition: background 250ms ease-in-out;
  transition: background 250ms ease-in-out;
  content: '';
}

.navigation .menu-item.current_page_item:after,
.navigation .menu-item.current_page_item a:after {
  background: none;
}

.navigation__impressum {
  position: relative;
  display: none;
}

@media only screen and (min-width: 768px) {
  .navigation__impressum {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 5%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 5%;
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    display: block;
  }
}

.navigation__impressum--mobile {
  display: block;
  position: absolute;
  width: 100vw;
  bottom: 30%;
  text-align: center;
}

.navigation__language-selector {
  position: relative;
  display: none;
}

@media only screen and (min-width: 768px) {
  .navigation__language-selector {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 10%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 10%;
    -ms-flex: 1 1 10%;
    flex: 1 1 10%;
    display: block;
    border: none;
  }
}

.navigation__language-selector--mobile {
  display: block;
  position: absolute;
  width: 100vw;
  bottom: 20%;
  text-align: center;
}

.mobile-nav {
  display: none;
  position: fixed;
  top: 50px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  /*@include linear-gradient(
                -18deg
                , rgb(0, 60, 255)
                , rgb(0, 43, 184)
                );*/
  background-color: #052080;
}

.mobile-nav a {
  color: #ffffff;
}

#toggle-menu {
  display: none;
}

#toggle-menu[type="checkbox"]:checked ~ .mobile-nav {
  display: block;
}

/*@include keyframes( gradient )
{
    0% {
        background-size: 300% 300%;
    }

    50% {
        background-size: 100% 100%;
    }

    100% {
        background-size: 300% 300%;
    }
}*/
.page-title {
  display: none;
}

@media only screen and (min-width: 768px) {
  .page-title {
    display: block;
    max-width: 1140px;
    margin: 30px 60px 30px 250px;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
}

.footer {
  padding: 20px 10px;
  color: #BBBBBB;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .footer {
    max-width: 1140px;
    margin: 30px 60px 20px 250px;
  }
}

.footer__contact {
  font-size: 10px;
}

@media only screen and (min-width: 768px) {
  .footer__contact {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .footer__contact {
    font-size: 14px;
  }
}

.footer a {
  color: #BBBBBB;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.blockquote {
  margin: 50px auto 100px auto;
  padding: 30px 0;
  width: 75%;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .blockquote {
    margin: 80px auto 200px auto;
    padding: 30px 0;
    width: 70%;
  }
}

@media only screen and (min-width: 992px) {
  .blockquote {
    margin: 80px auto 200px auto;
    padding: 30px 0;
  }
}

.blockquote:after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 66.666%;
  left: 50%;
  height: 1px;
  background-color: #ddd;
}

.blockquote__content {
  font-family: "Georgia", "Times New Roman", serif;
  font-style: italic;
  font-size: 18px;
  color: #000;
  top: 0;
  padding: 0;
  text-align: left;
}

@media only screen and (min-width: 992px) {
  .blockquote__content {
    font-size: 24px;
  }
}

.blockquote__content:before {
  position: absolute;
  left: -40px;
  top: -10px;
  content: '\201C';
  font-size: 80px;
  opacity: 0.75;
  padding-right: 10px;
}

@media only screen and (min-width: 768px) {
  .blockquote__content:before {
    left: -60px;
    top: -20px;
    font-size: 100px;
  }
}

.blockquote__author {
  margin-top: 10px;
  color: #787878;
  font-size: 14px;
  text-align: right;
}

.blockquote__author:before {
  content: ' – ';
}

.stories {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 25px;
}

.stories-single {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -moz-box-flex: 1;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .stories-single {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 45%;
    -moz-box-flex: 1;
    -moz-flex: 1 0 45%;
    -ms-flex: 1 0 45%;
    flex: 1 0 45%;
  }
}

.story {
  margin: 0 15px;
}

.story__text {
  position: relative;
  background-color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .story__text {
    top: -45px;
    margin-left: 50px;
    padding: 20px 30px 10px 25px;
  }
}

.story__text h2 {
  margin: 0;
}

.competences {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.competences-single {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 50%;
  -moz-box-flex: 1;
  -moz-flex: 1 1 50%;
  -ms-flex: 1 1 50%;
  flex: 1 1 50%;
}

.competence h2 {
  margin: 0;
}

.competence p {
  margin: 0 0 20px 0;
}

.link a {
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
}

/*@keyframes showButton {
  0% {
    box-shadow: 10px 5px 10px 0px #ffffff;
    background-color: #ffffff;
  }
  100% {
    box-shadow: 10px 5px 10px 0px #eeeeee;
    background-color: #fdfdd4;
  }
}*/
.cta {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #fdfdd4;
  -webkit-transition: box-shadow 250ms ease-in-out;
  -khtml-transition: box-shadow 250ms ease-in-out;
  -moz-transition: box-shadow 250ms ease-in-out;
  -ms-transition: box-shadow 250ms ease-in-out;
  -o-transition: box-shadow 250ms ease-in-out;
  transition: box-shadow 250ms ease-in-out;
  padding: 15px 30px;
}

.cta:hover {
  box-shadow: 6px 6px 12px 0 rgba(0, 0, 0, 0.35);
}

.cv {
  display: none;
}

@media only screen and (min-width: 1200px) {
  .cv {
    display: block;
  }
}

.vis-timeline {
  border-width: 0 1px !important;
  -webkit-transition: height 500ms ease-in-out;
  -khtml-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
  max-height: 80vh;
  visibility: visible !important;
}

.vis-content {
  padding: 2rem 0;
}

.cv-event {
  /*&__startdate,
  &__enddate {

  }*/
}

.cv-event__date {
  display: block;
  color: #797979;
  font-size: 0.8rem !important;
  font-weight: bold;
}

.cv-event__date * {
  font-size: inherit !important;
}

.cv-event__title {
  margin: 0 0 0.2rem 0;
  font-weight: bold;
  line-height: 1.25;
}

.cv-event__text {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vis-item {
  background-color: transparent !important;
  border: 1PX solid transparent !important;
  -webkit-transition: all 500ms ease-in-out;
  -khtml-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.vis-item:hover {
  opacity: 1 !important;
}

.vis-item:before {
  content: '';
  display: block;
  height: 200vh;
  top: 50%;
  width: 1px;
  position: absolute;
  left: 5px;
  -webkit-transition: background-color 500ms ease-in-out;
  -khtml-transition: background-color 500ms ease-in-out;
  -moz-transition: background-color 500ms ease-in-out;
  -ms-transition: background-color 500ms ease-in-out;
  -o-transition: background-color 500ms ease-in-out;
  transition: background-color 500ms ease-in-out;
}

.vis-item.vis-box {
  text-align: left !important;
}

.vis-item .vis-item-content {
  -webkit-transition: width 500ms ease-in-out;
  -khtml-transition: width 500ms ease-in-out;
  -moz-transition: width 500ms ease-in-out;
  -ms-transition: width 500ms ease-in-out;
  -o-transition: width 500ms ease-in-out;
  transition: width 500ms ease-in-out;
}

.vis-item__inner {
  cursor: pointer;
  background-color: transparent !important;
  border: 1PX solid transparent !important;
  border-radius: 6px;
  padding: 5px;
  -webkit-transition: font-size 500ms ease-in-out, background-color 500ms ease-in-out, border-color 500ms ease-in-out;
  -khtml-transition: font-size 500ms ease-in-out, background-color 500ms ease-in-out, border-color 500ms ease-in-out;
  -moz-transition: font-size 500ms ease-in-out, background-color 500ms ease-in-out, border-color 500ms ease-in-out;
  -ms-transition: font-size 500ms ease-in-out, background-color 500ms ease-in-out, border-color 500ms ease-in-out;
  -o-transition: font-size 500ms ease-in-out, background-color 500ms ease-in-out, border-color 500ms ease-in-out;
  transition: font-size 500ms ease-in-out, background-color 500ms ease-in-out, border-color 500ms ease-in-out;
}

.vis-item__inner:hover {
  border-color: #d8d8d8 !important;
}

.vis-item.vis-selected {
  width: 20% !important;
  -webkit-transform: scale(1.5);
  -khtml-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  transform-origin: bottom left;
}

.vis-item.vis-selected .vis-item-overflow {
  overflow: visible !important;
}

.vis-item.vis-selected .vis-item-content {
  width: auto !important;
}

.vis-item.vis-selected .vis-item-content .cv-event__text {
  white-space: normal !important;
  font-size: 0.75rem;
}

.vis-item.vis-selected:before {
  background-color: #C6C6C6;
}

.vis-item.vis-selected .vis-item__inner {
  background-color: #fff !important;
  border-color: #C6C6C6 !important;
}

.vis-item__inner .vis-item-overflow {
  overflow: visible !important;
}

.vis-item__inner * {
  transition: all 500ms ease-in-out;
  font-size: 1rem;
}

.vis-item__inner .cv-event__text {
  font-size: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vis-item__inner .cv-event__text,
.vis-item__inner .cv-event__title {
  text-overflow: ellipsis;
  overflow: hidden;
}

#visualization.zoomed .vis-item:not(.vis-selected) {
  opacity: 0.4;
  -webkit-transform: scale(0.75);
  -khtml-transform: scale(0.75);
  -moz-transform: scale(0.75);
  -ms-transform: scale(0.75);
  -o-transform: scale(0.75);
  transform: scale(0.75);
}

/*#visualization {
 &.zoomed {
   .vis-item {

     //border-color: #C6C6C6 !important;

     * {
       font-size: 16px;
     }

     &.vis-selected {
        //background-color: #fff !important;
       z-index: 2;

     }

     &:hover {
       z-index: 2;

       .vis-item__inner {
         background-color: #fff !important;
       }

       .vis-item-overflow {
         overflow: visible !important;
       }


       .cv-event__text,
       .cv-event__title {
         overflow: visible;
       }
     }

     &__inner {
       min-width: 200px;
       //max-width: 400px;
     }
     .cv-event__text,
     .cv-event__title {
       font-size: 16px;
       white-space: normal !important;
     }
   }
 }


}
*/
.vis-time-axis .vis-grid.vis-minor,
.vis-time-axis .vis-grid.vis-major {
  border-color: #fff !important;
}

.cv-event-categories {
  position: absolute;
  z-index: 666;
  left: 1PX;
  top: 1PX;
  padding: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -khtml-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
}

.cv-event-categories:hover {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.cv-event-categories__list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.cv-event-category {
  margin: 0 !important;
  list-style: none !important;
}

.cv-event-category__radio {
  display: none;
  visibility: hidden;
  height: 0;
}

.cv-event-category__radio:checked + .cv-event-category__name:before {
  background-color: currentColor;
}

.cv-event-category__radio:checked + .cv-event-category__name:after {
  opacity: 1;
}

.cv-event-category__name {
  position: relative;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9rem;
  padding-left: 30px;
}

.cv-event-category__name:before {
  position: absolute;
  background-color: transparent;
  content: '';
  left: 3px;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 2px solid currentColor;
  transition: all 250ms ease-in-out;
}

.cv-event-category__name:after {
  opacity: 0;
  position: absolute;
  left: 6px;
  top: 3px;
  content: '✔';
  font-size: 0.8rem;
  line-height: 1;
  color: #fff;
  transition: all 250ms ease-in-out;
}

#eventTimelineZoomOutButton {
  position: absolute;
  z-index: 666;
  top: 15px;
  border: none;
  right: 15px;
  font-size: 0.8rem;
  border-radius: 100px;
  background-color: #EFEFEF;
  font-weight: bold;
  padding: 0.35rem 0.85rem;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -khtml-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#eventTimelineZoomOutButton:hover {
  background-color: #C6C6C6;
}

#eventTimelineZoomOutButton:active {
  color: #000;
}

#eventTimelineZoomOutButton.zoomed {
  opacity: 1;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus, a:active {
  text-decoration: none;
  outline: none;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main {
  padding: 20px 0;
}

.title {
  -webkit-hyphens: none;
  -khtml-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  -o-hyphens: none;
  hyphens: none;
}

.title--centered {
  text-align: center;
}

.title--sans-serif {
  font-family: Arial, sans-serif;
}

.title--uppercase {
  text-transform: uppercase;
}

.title span {
  font-weight: normal;
}

.link--cta a {
  display: block;
  padding: 5px 8px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.simple-page__inner {
  max-width: 1140px;
  margin: 0 auto;
  padding: 30px 0;
}

@media only screen and (min-width: 992px) {
  .simple-page__inner {
    padding: 40px 0;
  }
}

@media only screen and (min-width: 1200px) {
  .simple-page__inner {
    padding: 50px 0;
  }
}

.simple-page__content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 100%;
  -moz-box-flex: 1;
  -moz-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  margin: 0 20px;
}

@media only screen and (min-width: 768px) {
  .simple-page__content {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 48%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 48%;
    -ms-flex: 1 1 48%;
    flex: 1 1 48%;
    margin-left: 200px;
    margin-right: 60px;
    max-width: 1140px;
  }
}

@media only screen and (min-width: 769px) {
  .simple-page__content {
    margin-left: 250px;
    margin-right: 60px;
    margin-top: 30px;
  }
}

.simple-page__content--cv {
  overflow: auto;
  margin: 0;
  margin-bottom: 30px;
}

.simple-page__content--cv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.simple-page__content--cv::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.simple-page__content--cv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

@media only screen and (min-width: 768px) {
  .simple-page__content--cv {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 48%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 48%;
    -ms-flex: 1 1 48%;
    flex: 1 1 48%;
    margin-left: 200px;
    margin-right: 60px;
    max-width: 1140px;
  }
}

@media only screen and (min-width: 769px) {
  .simple-page__content--cv {
    margin-left: 250px;
    margin-right: 60px;
    margin-top: 30px;
  }
}

.simple-page__content--cv .fl-photo {
  width: 240%;
}

@media only screen and (min-width: 480px) {
  .simple-page__content--cv .fl-photo {
    width: 200%;
  }
}

@media only screen and (min-width: 768px) {
  .simple-page__content--cv .fl-photo {
    width: 180%;
  }
}

@media only screen and (min-width: 992px) {
  .simple-page__content--cv .fl-photo {
    width: 150%;
  }
}

@media only screen and (min-width: 1200px) {
  .simple-page__content--cv .fl-photo {
    width: auto;
    display: none;
  }
}

.simple-page__content__title {
  margin: 20px 20px 0 0;
}

.simple-page__content__text {
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .simple-page__content__text {
    margin: 0 50px 0 0;
  }
}

.simple-page__content__text h2 + p {
  margin-top: 10px;
}

.simple-page__content__text p {
  max-width: 900px;
}

.simple-page__content__text p:first-child {
  margin-top: 0;
}

.simple-page__content ul > li {
  list-style-type: square;
  margin-left: 20px;
}

.simple-page__content ol > li {
  list-style-type: decimal;
  margin-left: 20px;
}

.simple-page__intro-content {
  position: relative;
  background: #fff;
  margin: 0 20px;
}

@media only screen and (min-width: 768px) {
  .simple-page__intro-content {
    margin-left: 240px;
    max-width: 1140px;
    padding: 15px 80px 0 50px;
    top: -60px;
  }
}

@media only screen and (min-width: 769px) {
  .simple-page__intro-content {
    margin-left: 310px;
    margin-bottom: -50px;
  }
}

.simple-page__intro-content__title {
  margin: 0px;
  padding: 0px;
}

.simple-page__intro-content h1 {
  font-weight: 700;
}

.simple-page__intro-content h2 {
  margin-top: -15px;
}

.simple-page__intro-image {
  margin-top: 30px;
  /*@include min-screen($defaultbreakpoint) {
            img {
                margin: 0 30px 0 200px;
                max-width: 70%
            }
        }*/
}

@media only screen and (min-width: 768px) {
  .simple-page__intro-image {
    margin: 0 80px 0 250px;
    max-width: 1140px;
  }
  .simple-page__intro-image img {
    width: 100%;
  }
}

.simple-page__wysiwyg-content blockquote {
  font-size: 24px;
}

@media only screen and (min-width: 992px) {
  .simple-page__wysiwyg-content blockquote {
    font-size: 28px;
  }
}

.simple-page__wysiwyg-content blockquote p {
  font-size: inherit;
}

.simple-page__wysiwyg-content ol,
.simple-page__wysiwyg-content ul {
  margin-left: 1em;
  list-style: disc;
}

.simple-page__wysiwyg-content dl {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.simple-page__wysiwyg-content dl dt {
  margin: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 30%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
}

.simple-page__wysiwyg-content dl dd {
  margin: 0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 70%;
  -moz-box-flex: 0;
  -moz-flex: 0 0 70%;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
}

@media only screen and (min-width: 768px) {
  .simple-page .multiple-column-layout {
    margin-left: 0;
    margin-right: 0;
  }
}

.front__content {
  position: relative;
  z-index: 10;
}

.front-page__competences {
  margin-top: 15px;
}

.front-page__button {
  text-align: center;
}

.testimonials {
  margin-top: 50px;
}

.testimonial {
  position: relative;
  margin-bottom: 100px;
}

.testimonial:last-of-type {
  margin-bottom: 125px;
}

.testimonial__logo {
  margin-bottom: 20px;
}

.testimonial__logo img {
  max-height: 80px;
  max-width: 260px;
  margin: 0;
}

.testimonial__header h2 {
  margin: 15px 0 10px 0;
  font-size: 28px;
  line-height: 1.2;
  font-weight: bold;
}

@media only screen and (min-width: 992px) {
  .testimonial__header h2 {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .testimonial__header h2 {
    margin-top: 25px;
  }
}

.testimonial__header h2 span {
  font-size: 20px;
  font-weight: normal;
}

.testimonial__content {
  position: relative;
  background-color: #fff;
  /*p {
              margin-top: 0;
              margin-bottom: 5px;
              
            }
	        ul, ol {
	        	font-size: 16px;
	        	margin: 0 0 2px -15px;
				margin-left: 0;
	        }

	        ul > li {
	        	padding-top: 3px !important;
                padding-bottom: 3px !important;
	        	margin-left: 15px;
	        }

	        h2 {
		    	margin: 0;
				line-height: 1.2;
				margin-bottom: 10px;
		    }	

		    h3 {
		    	font-weight: 600;
				margin: 5px 0;
		    }*/
}

.testimonial__content-text {
  position: relative;
  background-color: #f8f8f8;
  border-style: solid;
  border-color: #dddddd;
  border-width: 2px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.testimonial__content-text:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 46.5%;
  height: 100%;
  width: 7%;
  background: transparent url("/wp-content/themes/karlkellenberger/img/references-arrow.svg") no-repeat;
  background-size: 100% 102%;
  background-position: center center;
}

.testimonial__content-text h3 {
  color: #858585;
  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  margin: 0 0 15px 15px;
}

.testimonial__content-text p {
  font-size: 16px;
  margin-left: 15px;
}

.testimonial__content-text ul,
.testimonial__content-text ol {
  font-size: 16px;
  margin: 0 0 2px 0;
}

.testimonial__content-text ul li,
.testimonial__content-text ol li {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-left: 15px;
}

.testimonial__content-text-situation {
  padding: 15px 7% 15px 15px;
  background-color: #f0f0f0;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
}

@media only screen and (min-width: 992px) {
  .testimonial__content-text-situation {
    padding: 20px 7% 20px 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .testimonial__content-text-situation {
    padding: 25px 7% 20px 20px;
  }
}

.testimonial__content-text-benefit {
  padding: 15px 15px 15px 7%;
  background-color: #f8f8f8;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
}

@media only screen and (min-width: 992px) {
  .testimonial__content-text-benefit {
    padding: 20px 20px 20px 7%;
  }
}

@media only screen and (min-width: 1200px) {
  .testimonial__content-text-benefit {
    padding: 25px 20px 20px 7%;
  }
}

.signature {
  position: relative;
  float: left;
  top: 30px;
  margin-bottom: 30px;
}

.signature img {
  max-width: 150px;
}

.signature:after {
  font-weight: 100;
  content: "Karl Kellenberger";
}

.steckbrief {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 14px;
}

@media only screen and (min-width: 992px) {
  .steckbrief {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) {
  .steckbrief {
    margin-top: 50px;
    margin-left: 50px;
    max-width: 400px;
  }
}

.steckbrief h1 {
  font-size: 15px;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) {
  .steckbrief h1 {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1200px) {
  .steckbrief h1 {
    font-size: 19px;
  }
}

.services {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin-top: 30px;
}

.services__single {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 70%;
  -moz-box-flex: 1;
  -moz-flex: 1 1 70%;
  -ms-flex: 1 1 70%;
  flex: 1 1 70%;
}

@media only screen and (min-width: 480px) {
  .services__single {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 70%;
    -moz-box-flex: 0;
    -moz-flex: 0 1 70%;
    -ms-flex: 0 1 70%;
    flex: 0 1 70%;
  }
}

@media only screen and (min-width: 769px) {
  .services__single {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 25%;
    -moz-box-flex: 1;
    -moz-flex: 1 1 25%;
    -ms-flex: 1 1 25%;
    flex: 1 1 25%;
  }
}

.service {
  margin: 15px 0;
  padding: 20px 0;
}

.service:last-of-type {
  border-right: none;
}

.service__thumbnail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
  /*@include min-screen($defaultbreakpoint+1) {
	    		height: 180px;
			}*/
}

.service__thumbnail img {
  display: block;
  max-width: 75%;
}

.service__content ul {
  font-size: 14px;
}

@media only screen and (min-width: 992px) {
  .service__content ul {
    font-size: 16px;
  }
}

.service__content h2 {
  font-size: 18px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 992px) {
  .service__content h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .service__content h2 {
    font-size: 22px;
  }
}
