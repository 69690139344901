%paragraph__column--resize {
    -webkit-columns: 300px 2;
       -moz-columns: 300px 2;
            columns: 300px 2;
}


.simple-page {

    &__inner {

        max-width: $max-page-width;
        margin: 0 auto;
        padding: 30px 0;

        @include min-screen($md) {
            padding: 40px 0;
        }

        @include min-screen($lg) {
            padding: 50px 0;
        }

    }

    &__content {

        @include flex(1 1 100%);
        margin: 0 20px;

        //Tablet
        @include min-screen($sm) {
            @include flex(1 1 48%);
            margin-left: $margin-left--tablet;
            margin-right: $margin-right--tablet;
            max-width: $max-page-width;
        }

        //Desktop
        @include min-screen($defaultbreakpoint + 1) {
            margin-left: $margin-left--desktop;
            margin-right: $margin-right--desktop;
            margin-top: 30px;
        }

        &--cv {
          overflow: auto;
          margin: 0;
          margin-bottom: 30px;

          &::-webkit-scrollbar-track
          {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            border-radius: 10px;
            background-color: #F5F5F5;
          }

          &::-webkit-scrollbar
          {
            width: 12px;
            background-color: #F5F5F5;
          }

          &::-webkit-scrollbar-thumb
          {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
            background-color: #555;
          }

          //Tablet
          @include min-screen($sm) {
            @include flex(1 1 48%);
            margin-left: $margin-left--tablet;
            margin-right: $margin-right--tablet;
            max-width: $max-page-width;
          }

          //Desktop
          @include min-screen($defaultbreakpoint + 1) {
            margin-left: $margin-left--desktop;
            margin-right: $margin-right--desktop;
            margin-top: 30px;
          }

          .fl-photo {
            width: 240%;

            @include min-screen($xs) {
              width: 200%;
            }

            @include min-screen($sm) {
              width: 180%;
            }

            @include min-screen($md) {
              width: 150%;
            }


            @include min-screen($lg) {
              width: auto;
              display: none;
            }
          }
        }


        //fixing weird margins of Beaver Builder
        &__title {
            margin: 20px 20px 0 0;
            h1 {
                @extend %title--first;
                //margin: 0 !important;
            }
            h2 {
                @extend %title--second;
            }
        }

        &__text {
            margin: 0;

            @include min-screen($defaultbreakpoint) {
                margin: 0 50px 0 0;
            }

            h2 + p {
              margin-top: 10px;
            }
            p {
                max-width: $max-paragraph-width;

              &:first-child {
                margin-top: 0;
              }
            }
        }

        ul > li {
            list-style-type: square;
            margin-left: 20px;
        }
        ol > li {
            list-style-type: decimal;
            margin-left: 20px;
        }
    }

//Top of Page Elements
    &__intro-content {
        position: relative;
        background: #fff;
        margin: 0 20px;

        @include min-screen($sm) {
            margin-left: $margin-left--tablet + 40px;
            max-width: $max-page-width;
            padding: 15px 80px 0 50px;
            top: -60px;
        }

        @include min-screen($defaultbreakpoint + 1) {
            margin-left: $margin-left--desktop + 60px;
            margin-bottom: -50px;
        }

        &__title {
            margin: 0px;
            padding: 0px;
        }

        h1 {
            @extend %title--first;
            font-weight: 700;
        }

        h2 {
            @extend %title--second;
            margin-top: -15px;
        }

    }

    &__intro-image {
        margin-top: 30px;

       /*@include min-screen($defaultbreakpoint) {
            img {
                margin: 0 30px 0 200px;
                max-width: 70%
            }
        }*/

        @include min-screen($defaultbreakpoint) {
            margin: 0 80px 0 $margin-left--desktop;
            max-width: $max-page-width;

            img {
                width: 100%;
            }
        }
    }

    &__wysiwyg-content {

        h1 {
            @extend %title--first;
        }

        h2 {
            @extend %title--second;
        }

        p {
            @extend %paragraph-font-size;
        }

        blockquote {
            font-size: 24px;

        	@include min-screen($md) {
        		font-size: 28px;
        	}

        	p {
            	font-size: inherit;
        	}
        }

        ol,
        ul {
            @extend %paragraph-font-size;
            margin-left: 1em;
            list-style: disc;
        }

        dl {

            @include flexbox();
            @include flex-wrap(wrap);

            dt {
                margin: 0;
                @include flex(0 0 30%);
            }
            dd {
                margin: 0;
                @include flex(0 0 70%);
            }
        }

    }

    .multiple-column-layout {
        @include min-screen($defaultbreakpoint) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
