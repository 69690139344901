.hamburger {
    //used hamburger library
    @include min-screen($defaultbreakpoint) {
        display: none;
    }
    .hamburger-inner {
        background-color: #fff;

        &:before,
        &:after {
            background-color: #fff;
        }
    }
}
