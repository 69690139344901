.testimonials {
	margin-top: 50px;
}

.testimonial {
		position: relative;
		margin-bottom: 100px;

		&:last-of-type {
			margin-bottom: 125px;
		}

		&__logo {
			margin-bottom: 20px;

			img {
				max-height: 80px;
				max-width: 260px;
				margin: 0;
			}
		}

		&__header {
			h2 {
				margin: 15px 0 10px 0;
				font-size: 28px;
				line-height: 1.2;
				font-weight: bold;

				@include min-screen($md) {
					margin-top: 20px;
				}
				@include min-screen($lg) {
					margin-top: 25px;
				}

				span {
					font-size: 20px;
					font-weight: normal;
				}
			}
		}

		&__content {
			position: relative;
			background-color: #fff;
			//margin-left: 25px;

			@include min-screen($defaultbreakpoint) {
                //top: -45px;
				//margin-left: 50px;
				//padding: 20px 25px 10px 25px;
				//border-bottom: 20px solid #eee;
	        }

            /*p {
              margin-top: 0;
              margin-bottom: 5px;
              
            }
	        ul, ol {
	        	font-size: 16px;
	        	margin: 0 0 2px -15px;
				margin-left: 0;
	        }

	        ul > li {
	        	padding-top: 3px !important;
                padding-bottom: 3px !important;
	        	margin-left: 15px;
	        }

	        h2 {
		    	margin: 0;
				line-height: 1.2;
				margin-bottom: 10px;
		    }	

		    h3 {
		    	font-weight: 600;
				margin: 5px 0;
		    }*/


		}

		&__content-text {
			position: relative;
			background-color: #f8f8f8;
			border-style: solid;
			border-color: #dddddd;
			border-width: 2px 0;
			@include flexbox();
			@include justify-content(space-between);

			// arrow
			&:after {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				left: 46.5%;
				//margin-left: -2px;
				height: 100%;
				width: 7%;
				background: transparent url('/wp-content/themes/karlkellenberger/img/references-arrow.svg') no-repeat;
				background-size: 100% 102%;
				background-position: center center;
			}

			h3 {
				color: #858585;
				font-family: $font-family--sans-serif;
				font-size: 13px;
				font-weight: bold;
				margin: 0 0 15px 15px;
			}

			p {
				font-size: 16px;
				margin-left: 15px;
			}

			ul,
			ol {
				font-size: 16px;
				margin: 0 0 2px 0;

				li {
					padding-top: 4px !important;
					padding-bottom: 4px !important;
					margin-left: 15px;
				}
			}

			&-situation {
				padding: 15px 7% 15px 15px;
				background-color: #f0f0f0;
				@include flex(0 1 50%);

				@include min-screen($md) {
					padding: 20px 7% 20px 20px;
				}
				@include min-screen($lg) {
					padding: 25px 7% 20px 20px;
				}
			}

			&-benefit {
				padding: 15px 15px 15px 7%;
				background-color: #f8f8f8;
				@include flex(0 1 50%);

				@include min-screen($md) {
					padding: 20px 20px 20px 7% ;
				}
				@include min-screen($lg) {
					padding: 25px 20px 20px 7% ;
				}
			}
		}
}