%padding-xy {
    
    padding: 30px 0;
    
	@include min-screen($md) {
		padding: 40px 0;
	}
	@include min-screen($lg) {
		padding: 50px 0;
	}
    
}


%padding-x {
    
    padding-left: 50px;
    padding-right: 50px;
    
	@include min-screen($md) {
		padding-left: 80px;
		padding-right: 80px;
	}
	@include min-screen($lg) {
		padding-left: 10%;
		padding-right: 10%;
	}
	@include min-screen($xlg) {
		padding-left: 0;
		padding-right: 0;
	}
    
}



%padding-y {
    padding-top: 30px;
    padding-bottom: 30px;
    
	@include min-screen($md) {
        padding-top: 40px;
        padding-bottom: 40px;
	}
	@include min-screen($lg) {
        padding-top: 50px;
        padding-bottom: 50px;
	}
}

%padding-y--small {
    padding-top: 10px;
    padding-bottom: 10px;
    
	@include min-screen($md) {
        padding-top: 15px;
        padding-bottom: 15px;
	}
	@include min-screen($lg) {
        padding-top: 20px;
        padding-bottom: 20px;
	}
}


// Titles/Header
%title--first {

	font-family: $font-family--titles;
	font-size: 30px;
	margin-bottom: 15px;
	line-height: 1.2;
	font-weight: 900;
    @include css3-prefix(hyphens, none);

	@include min-screen($md) {
		font-size: 32px;
	}
	@include min-screen($lg) {
		font-size: 36px;
	}
	
	span {
    	font-weight: 100;
	}
}



%title--second {

	font-family: $font-family--titles;
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: 600;
    @include css3-prefix(hyphens, none);

	@include min-screen($md) {
		font-size: 20px;
	}
	@include min-screen($lg) {
		font-size: 22px;
	}
}



%title--third {

	font-family: $font-family--titles;
	font-size: 16px;
	margin-bottom: 10px;
	font-weight: bold;
    @include css3-prefix(hyphens, none);

	@include min-screen($md) {
		font-size: 18px;
	}
	@include min-screen($lg) {
		font-size: 20px;
	}
}


%title--fourth {

	font-family: $font-family--titles;
	font-size: 14px;
	margin-bottom: 10px;
	font-weight: bold;
    @include css3-prefix(hyphens, none);

	@include min-screen($md) {
		font-size: 16px;
	}
	@include min-screen($lg) {
		font-size: 18px;
	}
}


%paragraph-font-size {
    
    font-size: 16px;
    
	@include min-screen($md) {
		font-size: 18px;
	}
	@include min-screen($lg) {
		font-size: 20px;
	}
}

%paragraph-font-size--small {
    
    font-size: 12px;
    
	/*@include min-screen($sm) {
		font-size: 18px;
	}
	*/
	@include min-screen($md) {
		font-size: 14px;
	}
	/*@include min-screen($lg) {
		font-size: 30px;
	}*/
}