/*@keyframes showButton {
  0% {
    box-shadow: 10px 5px 10px 0px #ffffff;
    background-color: #ffffff;
  }
  100% {
    box-shadow: 10px 5px 10px 0px #eeeeee;
    background-color: #fdfdd4;
  }
}*/

.cta {
  //animation: 3s ease-out 0s 1 showButton;
  @extend %paragraph-font-size;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  box-shadow: 3px 3px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #fdfdd4;
  @include transition(box-shadow $transition-duration $transition-timing);
  padding: 15px 30px;

  &:hover {
    box-shadow: 6px 6px 12px 0 rgba(0, 0, 0, 0.35);
  }
}