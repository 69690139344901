.front {
    &__content {
        position: relative;
        z-index: 10;
    }
}

.front-page {

    &__competences {
        margin-top: 15px;
    }

    &__button {
        text-align: center;
    }
}